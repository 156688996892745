<template>
    <main id="liste">
        <HeaderTab
            :title="$t('monte.resa_liste.gestion_expeditions')"
            :swiper_tabs="swiper_tabs"
            :swiper_action="changeTab"
        />

		<div id="content">
			<div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                    	<div class="box">
							<SeasonInput v-model="seasonSelected" @change="changeSeason" />

							<OrderList
								ref="table_order"
								v-if="tableId == 'monte_resa_commande'"
								:items="items"
								:table_busy="table_busy"
							/>

							<BonList
								ref="table_bon"
								v-else-if="tableId == 'monte_resa_bon'"
								:items="items"
								:table_busy="table_busy"
							/>

							<TrackList
								ref="table_track"
								v-else-if="tableId == 'monte_resa_suivi'"
								:items="items"
								:table_busy="table_busy"
							/>

							<Delivered
								ref="table_delivred"
								v-else-if="tableId == 'monte_resa_delivered'"
								:items="items"
								:table_busy="table_busy"
							/>

							<NotDelivered
								ref="table_not_delivred"
								v-else-if="tableId == 'monte_resa_not_delivered'"
								:items="items"
								:table_busy="table_busy"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ModalAddResa
			ref="modalAddResa"
			:available_tiers="available_tiers"
			:available_centre_stockage="available_centre_stockage"
			:available_mares="available_mares"
			:season="seasonSelected"
			@ok="refreshTable"
		/>
		<ModalAddOrder
			ref="modalAddOrder"
			@ok="refreshTable"
		/>

		<ModalEditDates
			ref="modalEditDates"
			:reservation-order.sync="interacted_item"
			@ok="saveOrderDates"
		/>

		<ModalEditCentre
			ref="modalEditCentre"
			:available_tiers="available_centre_stockage"
			:reservation_order="monte_resa_selected"
			@ok="refreshTable"
		/>

		<ModalEditPaillette
			ref="modalEditPaillette"
			:reservations="monte_resa_selected"
			@ok="refreshTable"
		/>

		<ModalSaillie
			ref="modalSaillie"
			@ok="refreshTable"
		/>
    </main>
</template>


<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Contract from "@/mixins/Contract.js"
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"
    import Navigation from "@/mixins/Navigation.js"
    import Tiers from "@/mixins/Tiers.js"
    import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: "monteReservation",
		mixins: [
			Contract,
			MonteReservationOrder,
            Navigation,
			Tiers
        ],
		data () {
			return {
				table_busy: true,
				items: [],
				interacted_item: null,
				monte_resa_selected: null,
				available_tiers: [],
				available_centre_stockage: [],
				available_mares: [],
				seasonSelected: null,
				config_table_hrefs: {
					'stallion.horse_nom': {
						routeUniqueName: 'stallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'mare.horse_nom': {
						routeUniqueName: 'mareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'contract.contract_num': {
						routeUniqueName: 'contractFiche',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract.contract_id'
						}
					},
				},
				swiper_tabs: [
					{
                        label: 'monte.resa_liste.header.commande_preparer',
                        active: true,
                        name: 'commande'
                    }, {
                        label: 'monte.resa_liste.header.bon_sortie',
                        active: false,
                        name: 'bon'
                    }, {
                        label: 'monte.resa_liste.header.suivi_expeditions',
                        active: false,
                        name: 'suivi'
                    }, {
                        label: 'monte.resa_liste.header.livrees',
                        active: false,
                        name: 'delivered'
                    }, {
                        label: 'monte.resa_liste.header.non_livrees',
                        active: false,
                        name: 'not_delivered'
                    }
                ],
                actual_tab: null,
                events_tab: {
					"TableAction::goToAddResa": this.addForm,
					"TableAction::goToEditResa": this.editForm,
					"TableAction::goToDeleteMonteResa": this.deleteResa,
					"TableAction::goToBonSortieResa": this.bonSortie,
					"TableAction::goToNotifyExpediteur": this.notifyExpediteur,
					"TableAction::goToAckExpedition": this.ackExpedition,
					"TableAction::goToAckDelivered": this.ackDelivered,
					"TableAction::goToAckNotDelivered": this.ackNotDelivered,
					"TableAction::goToEditMonteOrder": this.showEditOrderDates,
					"TableAction::goToEditCentre": this.showEditCentre,
					"TableAction::goToEditResaBis": this.editFormBis, // étape 3 et 4 (expédié et livré) annulation + création nouvelle ligne
                }
			}
        },
		mounted() {
            this.init_component()
		},
		methods: {
            /* Initialisation des composants */
            async init_component() {
                if(!this.actual_tab) {
	                this.actual_tab = this.swiper_tabs[0]
				}

				this.loadTiers()
					.then(res => this.available_tiers = res)

				this.available_centre_stockage = await this.loadCentreStockage()

				this.loadJuments()
					.then(res => this.available_mares = res)
            },

			async refreshTable() {
				if(this.seasonSelected) {
					this.table_busy = true
	                const items = await this.getSaillieReservations(this.seasonSelected.begin, this.seasonSelected.end)
	                this.items = await this.formatItem(items)

	                this.table_busy = false
	            }
				EventBus.$emit('MonteReservation::RefreshOrder')
				EventBus.$emit('MonteReservation::RefreshBon')
				EventBus.$emit('MonteReservation::RefreshTrack')
				this.endAction()
			},

			formatItem(items) {
				const departement_DOM_TOM = ['971','972','973','974','975','976','984','986','987','988']
				for( let item of items){
					if (item.destinataire && item.destinataire.tiers_country === 75 && item.destinataire.tiers_postalcode){
						item.departement_destination = item.destinataire.tiers_postalcode.substr(0,2)
						if(departement_DOM_TOM.includes(item.destinataire.tiers_postalcode.substr(0,3))){
							item.departement_destination = item.destinataire.tiers_postalcode.substr(0,3)
						}
					}
				}
				return items
			},

			/* Hook changement de table */
			changeTab(tab) {
				const prevTab  = this.swiper_tabs.find(t => t.active === true)
				const actualTab = this.swiper_tabs.find(t => t.name == tab.name)
                prevTab.active  = false
                actualTab.active = true
                this.actual_tab = _cloneDeep(actualTab)
			},

			/* Actions */
			addForm() {
				this.$refs.modalAddResa.openModal()
			},
			editForm(resa) {
				this.$refs.modalAddResa.openModal(resa.id)
			},
			editFormBis(resa) {
				this.$refs.modalAddResa.openModal(resa.id, true)
				
				if (this.tableId == 'monte_resa_commande')
					this.$refs.table_order.unselectAll()
				if (this.tableId == 'monte_resa_bon')
					this.$refs.table_bon.unselectAll()
				if (this.tableId == 'monte_resa_suivi')
					this.$refs.table_track.unselectAll()
				if (this.tableId == 'monte_resa_not_delivered')
					this.$refs.table_not_delivred.unselectAll()
				if (this.tableId == 'monte_resa_delivered')
					this.$refs.table_delivred.unselectAll()
			},
			async deleteResa(resas) {
				let tab_promises = []

				resas.forEach(resa => {
					tab_promises.push(this.deleteSaillieReservation(resa.id))
				})

				await Promise.all(tab_promises)

				await this.refreshTable()
				this.$refs.table_order.unselectAll()
			},
			async bonSortie(resas) {
				// On vérifie que toutes les résas ont un destinataire ET un expéditeur
				const cannot_order = resas.find(r => r.computed_can_be_ordered === false)
				
				if(cannot_order) {
					this.failureToast('monte.resa_liste.cannot_order')
					return
				}

				let tab_promises = []
				const order_date = new Date()

				resas.map(r => r.id)
					.forEach(r_id => {
						tab_promises.push(this.setReservationOrderStatus(r_id, { order_date }))
				})

				await Promise.all(tab_promises)
				this.refreshTable()
				EventBus.$emit('MonteReservation::RefreshOrder')
			},

			notifyExpediteur(resa_ids) {
				this.$refs.modalAddOrder.openModal(resa_ids)
			},
			async ackExpedition(resas) {
				const entities = resas.filter(resa => resa.expediteur.tiers_entity)
				this.monte_resa_selected = resas
				
				if(entities.length == resas.length) {
					this.$refs.modalEditPaillette.openModal(true)
				}
				else if(entities.length === 0) {
					this.$refs.modalEditPaillette.openModal()
				}
				else if(entities.length < resas.length) {
					this.failureToast('monte.resa_liste.not_all_entities')
				}
			},
			async ackDelivered(resas) {
				this.monte_resa_selected = resas
				let tab_promises = []
				const delivery_date = new Date()
				const not_delivery_date = null

				resas.map(r => r.id)
					.forEach(r_id => {
						tab_promises.push(this.setReservationOrderStatus(r_id, { delivery_date, not_delivery_date }))
				})

				await Promise.all(tab_promises)

				let contracts_ids = resas.map(r => {
					return r.contract ? r.contract.contract_id : null
				}).filter(r => (!!r))
				this.forceIndexationContractResa([contracts_ids])

				this.submitConfirm()
				this.refreshTable()
				EventBus.$emit('MonteReservation::RefreshTrack')
				this.endAction()
			},
			async ackNotDelivered(resas) {
				let tab_promises = []
				const not_delivery_date = new Date()
				const delivery_date = null

				resas.map(r => r.id)
					.forEach(r_id => {
						tab_promises.push(this.setReservationOrderStatus(r_id, { not_delivery_date, delivery_date }))
				})

				let contracts_ids = resas.map(r => {
					return r.contract ? r.contract.contract_id : null
				}).filter(r => (!!r))
				this.forceIndexationContractResa([contracts_ids])

				await Promise.all(tab_promises)
				this.refreshTable()
				EventBus.$emit('MonteReservation::RefreshTrack')
				this.endAction()
			},
			showEditOrderDates(monte_resa) {
				this.interacted_item = monte_resa
				this.$refs.modalEditDates.openModal()
			},
			saveOrderDates(monte_order) {
				this.setReservationOrderStatus(this.interacted_item.id, monte_order)
					.then(() => {
						this.successToast()
						this.interacted_item = null
						this.refreshTable()
					})
			},
			showEditCentre(monte_resa) {
				this.monte_resa_selected = monte_resa
				this.$refs.modalEditCentre.openModal()
			},
			changeSeason() {
				this.refreshTable()
			},
			endAction() {
				EventBus.$emit("TableAction::stopSpin")
				if (this.tableId == 'monte_resa_commande')
					this.$refs.table_order.unselectAll()
				if (this.tableId == 'monte_resa_bon')
					this.$refs.table_bon.unselectAll()
				if (this.tableId == 'monte_resa_suivi')
					this.$refs.table_track.unselectAll()
				if (this.tableId == 'monte_resa_not_delivered')
					this.$refs.table_not_delivred.unselectAll()
				if (this.tableId == 'monte_resa_delivered')
					this.$refs.table_delivred.unselectAll()
			},

			submitConfirm() {
				let mare_saillie = []
				this.monte_resa_selected.forEach(resa => {
					if(resa.mare && resa.doses > 0) {
						mare_saillie.push(resa.mare)
					}
				})
				mare_saillie = mare_saillie.filter((value, index, self) =>
					index === self.findIndex((t) => (
						t.horse_id === value.horse_id
					))
				)
				this.$refs.modalSaillie.openModal(mare_saillie)
				this.refreshTable()
			}
        },
        computed: {
			tableId() {
				if(!this.actual_tab) return ''
				return `monte_resa_${this.actual_tab.name}`
			}
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
			ModalAddResa : () => import('@/components/Contract/Reservation/ModalAdd'),
			ModalAddOrder : () => import('@/components/Contract/Reservation/ModalAddOrder'),
			ModalEditDates : () => import('@/components/Contract/Reservation/ModalEditDates'),
			ModalEditCentre : () => import('@/components/Contract/Reservation/ModalEditCentre'),
			ModalEditPaillette: () => import('@/components/Contract/Reservation/ModalEditPaillette'),
			OrderList : () => import('@/components/Contract/Reservation/Suivi/Order'),
			BonList   : () => import('@/components/Contract/Reservation/Suivi/Bon'),
			TrackList : () => import('@/components/Contract/Reservation/Suivi/Track'),
			Delivered : () => import('@/components/Contract/Reservation/Suivi/Delivered'),
			NotDelivered : () => import('@/components/Contract/Reservation/Suivi/NotDelivered'),
			ModalSaillie : () => import('@/components/Contract/Reservation/ModalSaillie'),
			SeasonInput : () => import('@/components/Inputs/SeasonInput')
        }
	}
</script>
